// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see ttps://fresco-docs.netlify.com/#/carousel/background-video

$(".header-search").insertAfter(".headerContent .tagline");

$("section.homeFeature.homeFeatureSnippets.homeBox4").insertAfter(
  ".homeFeaturesWrapper"
);

// https://impactful.slack.com/archives/C45KGSY2U/p1583908584000800?thread_ts=1583206331.000100&cid=C45KGSY2U
$(".selectDonationAmount > span:first-child").html(
  'Please select a donation amount below: <em class="alert">*</em>'
);


// Donation amount - Radio button
$(".yourDonation .formQuestion.donationAmount").each(function() {
  var input =  $(this).find("input[type='radio']");
  var donationAmount = $(this).find(".donationAmountFigure");

  $(input).insertBefore(donationAmount);
});

// Other amount label DES-4877
if ($('body').hasClass('donate')) {
  $('#donationAmountOtherLabel .donationAmountFigure').text('Other amount');
}

// 998917 SA - Raisley donate page
$('document').ready(function () {
  if ($('body').hasClass('appeal-donate-today')
    || $("body").hasClass('appeal-donate-to-our-survive-and-thrive-campaign-this-eofy')
    || $("body").hasClass('appeal-hazzys-christmas-wish')) {      
    $(".donationwidget").appendTo(".title");
  }
})